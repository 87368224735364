export const USER_TYPE: { [key: number]: string } = {
  0: 'users.userType.admin',
  1: 'users.userType.accountant',
  2: 'users.userType.top.up',
  3: 'users.userType.order',
};

export const USER_TYPE_VALUE = {
  admin: 0,
  accountant: 1,
  topUp: 2,
  order: 3,
};

export const PERMISSION_USERS = {
  CREATE: 'UsersModule.create',
  VIEW: 'UsersModule.view',
  UPDATE: 'UsersModule.update',
  DELETE: 'UsersModule.delete',
  DASHBOARD: 'UsersModule.dashboard',
  TRANSACTION: 'TransactionsModule.commit',
};
